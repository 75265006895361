exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-templates-leo-universe-index-jsx": () => import("./../../../src/templates/LeoUniverse/index.jsx" /* webpackChunkName: "component---src-templates-leo-universe-index-jsx" */),
  "component---src-templates-multi-offer-index-jsx": () => import("./../../../src/templates/MultiOffer/index.jsx" /* webpackChunkName: "component---src-templates-multi-offer-index-jsx" */),
  "component---src-templates-new-template-index-jsx": () => import("./../../../src/templates/NewTemplate/index.jsx" /* webpackChunkName: "component---src-templates-new-template-index-jsx" */),
  "component---src-templates-not-available-index-jsx": () => import("./../../../src/templates/NotAvailable/index.jsx" /* webpackChunkName: "component---src-templates-not-available-index-jsx" */)
}

