import React from "react"

import {
  WHOLE_BANNER_CLICKABLE,
  GREEN_CTA,
  CENTERED_TEXT,
  DARK_OVERLAY,
  BLACK_TEXT,
  OVERLAPPING_GAMES,
  ARROW_ANIMATION,
  PULSATING_CTA,
  ARROW_IN_CTA,
  COMPACT_THREE_STEPS,
  MINI_THREE_STEPS,
  PLAIN_BANNER,
  HIDE_GAMES_CONTENT_SECTION,
  HIDE_SUPPORT_SECTION,
  HIDE_AWARDS_SECTION,
  HIDE_DOWNLOAD_APP_SECTION
} from "./constants"
import setNetreferCookies from "./setNetreferCookies"

// const LANG = "lang-"
// const LANG_EN = `${LANG}en`

const getLanguage = slug => slug && slug.split("/")[0]

const getDecimalOdds = americanOdds => (americanOdds > 0 ? americanOdds / 100 + 1 : -(100 / americanOdds) + 1).toFixed(2)

// Replace addition symbol with a space
const decode = str => decodeURIComponent(str.replace(/\+/g, " "))

const transformLanguage = language => {
  switch (language) {
    case "sv":
      return "se"
    case "en":
      return "gb"
    case "nz":
    case "jp":
      return "row"
    default:
      return language
  }
}
const transformDomain = language => {
  switch (language) {
    case "it":
      return "it"
    case "es":
      return "es"
    case "nl":
      return "nl"
    case "de":
      return "de"
    case "gb": // Temporary guard for UK
      return "co.uk"
    case "dk":
      return "dk"
    default:
      return "com"
  }
}

const getMarketLicense = country => {
  switch (country) {
    case "CA_ON":
      return "CAON"
    case "DK":
      return "DGA"
    case "ES":
      return "DGOJ"
    case "GB":
      return "UKGC"
    case "IT":
      return "AAMS"
    case "NL":
      return "KSA"
    case "SE":
      return "SGA"
    default:
      return "MGA"
  }
}

const getBannerStyle = bannerStyle => {
  if (bannerStyle) {
    const isTextCentered = bannerStyle.includes(CENTERED_TEXT)
    const hasDarkOverlay = bannerStyle.includes(DARK_OVERLAY)
    const isBannerClickable = bannerStyle.includes(WHOLE_BANNER_CLICKABLE)
    const hasGreenCta = bannerStyle.includes(GREEN_CTA)
    const isTextBlack = bannerStyle.includes(BLACK_TEXT)
    const isGamesOverlapping = bannerStyle.includes(OVERLAPPING_GAMES)
    const hasArrow = bannerStyle.includes(ARROW_ANIMATION)
    const isCtaPulsating = bannerStyle.includes(PULSATING_CTA)
    const hasCtaArrow = bannerStyle.includes(ARROW_IN_CTA)
    const isThreeStepsCompact = bannerStyle.includes(COMPACT_THREE_STEPS)
    const isThreeStepsMini = bannerStyle.includes(MINI_THREE_STEPS)
    const isPlainBanner = bannerStyle.includes(PLAIN_BANNER)
    const isH1Lowercase = bannerStyle.includes("H1 Lowercase")
    const halfWidthMobileTextOffer = bannerStyle.includes("half width mobile text offer")

    return {
      isTextCentered,
      hasDarkOverlay,
      isBannerClickable,
      hasGreenCta,
      isTextBlack,
      isGamesOverlapping,
      hasArrow,
      isCtaPulsating,
      hasCtaArrow,
      isThreeStepsCompact,
      isThreeStepsMini,
      isPlainBanner,
      isH1Lowercase,
      halfWidthMobileTextOffer
    }
  }
  return {}
}

const getSettings = settings => {
  if (settings) {
    const hideGamesContentSection = settings.includes(HIDE_GAMES_CONTENT_SECTION)
    const hideSupportSection = settings.includes(HIDE_SUPPORT_SECTION)
    const hideAwardsSection = settings.includes(HIDE_AWARDS_SECTION)
    const hideDownloadAppSection = settings.includes(HIDE_DOWNLOAD_APP_SECTION)

    return {
      hideGamesContentSection,
      hideSupportSection,
      hideAwardsSection,
      hideDownloadAppSection
    }
  }
  return {}
}

const getPageSettings = pageSettings => {
  if (pageSettings) {
    return {
      isBlackText: pageSettings.includes("Banner Black Text"),
      isOrangeCTA: pageSettings.includes("Orange CTA"),
      hasJackpot: pageSettings.includes("Jackpot Live Ticker"),
      hasGameAnimation: pageSettings.includes("Has Game Animation"),
      hasSpinningWheel: pageSettings.includes("Spinning Wheel"),
      hidePaymentLogos: pageSettings.includes("Hide Payment Logos"),
      showPaymentLogosOutsideBanner: pageSettings.includes("Show Payment Logos Outside Banner"),
      isOrangeBgLayout: pageSettings.includes("Orange Background Layout"),
      orangeHighlight: pageSettings.includes("Orange Highlight"),

      // new template
      themeOrangeMobile: pageSettings.includes("Theme Orange Mobile"),
      themeOrangeDesktop: pageSettings.includes("Theme Orange Desktop"),
      themeWhiteMobile: pageSettings.includes("Theme White Mobile"),
      themeWhiteDesktop: pageSettings.includes("Theme White Desktop"),

      // ab test
      isABTest: pageSettings.includes("AB test"),

      // from generic content
      showCollapsibleTerms: pageSettings.includes("Collapsible terms"),
      showSocialProof: pageSettings.includes("Show Social Proof Section"),
      showAwardsSection: pageSettings.includes("Show Awards Section")
    }
  }

  return {}
}

const getBannerV2Colour = bannerV2Colours => {
  if (bannerV2Colours?.includes("Sunrise")) {
    return "#F18825"
  }

  if (bannerV2Colours?.includes("Wine")) {
    return "#500800"
  }

  return "#F18825"
}

const getLogos = logos =>
  logos &&
  logos.map(asset => ({
    link: asset.description !== "" ? asset.description : null,
    url: asset.file.url,
    title: asset.title
  }))

const getLinkWithQuery = (link, searchParams) => `${link}${searchParams}`

const getMarkdownHtml = value => value?.childMarkdownRemark?.html

const getRawMarkdown = value => value?.childMarkdownRemark?.rawMarkdownBody

const getDangerousHtml = html => ({ __html: html })

const getImageFluid = image => image?.fluid

const getImageFixed = image => image?.fixed

const getImageFileUrl = image => image?.file?.url

const convertMarkdownToSpans = markdown => {
  if (!markdown) return null

  let _markdown = markdown

  _markdown = _markdown.replace(/(<p>|<\/p>)/g, "")

  _markdown = _markdown.replace("&#x26;", "&")

  _markdown = _markdown.split("\n")

  _markdown = _markdown.map((line, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span style={{ display: "block" }} key={index}>
      {line}
    </span>
  ))

  return _markdown
}

const convertHexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

const convertHexToRgbaString = (hex, opacity) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) return "rgba(255, 255, 255, 1)"

  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
}

const getUserDevice = () => {
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(window?.navigator.userAgent) ? "mobile" : "desktop"
}

const appendParamToUrl = (url, paramName, paramValue) => {
  // Create a URL object
  const newUrl = new URL(url)

  // Check if the URL already has query parameters
  if (!newUrl.searchParams.has(paramName)) {
    // If the URL already has query params, append the new one with "&"
    newUrl.searchParams.set(paramName, paramValue)
  }
  window.history.pushState({}, "", newUrl)
}

export {
  getLanguage,
  getDecimalOdds,
  setNetreferCookies,
  decode,
  transformLanguage,
  transformDomain,
  getBannerStyle,
  getSettings,
  getBannerV2Colour,
  getPageSettings,
  getLogos,
  getLinkWithQuery,
  getMarkdownHtml,
  getRawMarkdown,
  getDangerousHtml,
  getImageFluid,
  getImageFixed,
  getImageFileUrl,
  getMarketLicense,
  convertMarkdownToSpans,
  convertHexToRgb,
  convertHexToRgbaString,
  getUserDevice,
  appendParamToUrl
}
